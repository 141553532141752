<template>
  <div class="member-tag-condition">
    <BaseElFormItem label="完全包含" label-position="top">
      <div class="flex items-center" style="gap: 20px">
        <BaseElSelect
          v-model="syncModel.includes"
          clearable
          multiple
          popper-class="select-popper"
          @visible-change="showTagSelector = true; selectMode = 'includes'"
          @change="$emit('update')"
          @clear="$emit('update')"
        >
          <BaseElSelectOption
            v-for="(tagId, idx) in syncModel.includes"
            :key="idx"
            :label="tagOptionLabel(tagId)"
            :value="tagId"
          />
        </BaseElSelect>

        <i class="el-icon-circle-close cursor-pointer" style="font-size: 24px;" @click="$emit('remove')" />
      </div>
    </BaseElFormItem>

    <BaseElFormItem label="排除">
      <BaseElSelect
        v-model="syncModel.excludes"
        clearable
        multiple
        popper-class="select-popper"
        @visible-change="showTagSelector = true; selectMode = 'excludes'"
        @change="$emit('update')"
        @clear="$emit('update')"
      >
        <BaseElSelectOption
          v-for="(tagId, idx) in syncModel.excludes"
          :key="idx"
          :label="tagOptionLabel(tagId)"
          :value="tagId"
        />
      </BaseElSelect>
    </BaseElFormItem>

    <MemberTagAdvanceSelect
      :show.sync="showTagSelector"
      :options="filterTags"
      :modelValue="currentTags"
      @update:modelValue="onSelect"
      @close="showTagSelector = false"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, set } from 'vue'
import { useVModel } from '@vueuse/core'
import { get, find, filter, includes } from 'lodash'
import MemberTagAdvanceSelect from '@/components/Select/MemberTagAdvanceSelect.vue'

export default defineComponent({
  name: 'MemberTagCondition',
  components: { MemberTagAdvanceSelect },
  props: {
    model: { type: Object, default: () => ({ includes: [], excludes: [] }) },
    tags: { type: Array, default: () => [] },
  },
  setup (props, { emit }) {
    const syncModel = useVModel(props, 'model', emit)
    const selectMode = ref('includes')
    const showTagSelector = ref(false)
    const currentTags = computed(() => {
      if (selectMode.value === 'excludes') return get(syncModel.value, 'excludes')
      return get(syncModel.value, 'includes')
    })
    const onSelect = (newVal) => {
      if (selectMode.value === 'includes') set(syncModel.value, 'includes', newVal)
      else if (selectMode.value === 'excludes') set(syncModel.value, 'excludes', newVal)
      emit('update')
    }

    const tagOptionLabel = computed(() => {
      return (id) => {
        const target = find(props.tags, { id })
        return get(target, 'name')
      }
    })

    const filterTags = computed(() => {
      const includeTagIds = get(syncModel.value, 'includes')
      const excludeTagIds = get(syncModel.value, 'excludes')

      if (selectMode.value === 'includes') return filter(props.tags, (tag) => !includes(excludeTagIds, tag.id))
      return filter(props.tags, (tag) => !includes(includeTagIds, tag.id))
    })

    return { syncModel, showTagSelector, selectMode, onSelect, currentTags, tagOptionLabel, filterTags }
  },
})
</script>

<style lang="postcss" scoped>
::v-deep .el-form-item__label {
    @apply contents;
}
</style>
