<template>
  <div
    v-loading="memberTags.loading || members.loading"
    :element-loading-text="`會員標籤: ${memberTags.current}/${memberTags.total} | 會員資料: ${members.current}/${members.total}`"
    class="reward-postpone-condition-block side-highlight-container"
  >
    <p class="card-title">檢查對象</p>

    <p class="text-primary-100 font-bold" style="margin-bottom: 30px">預計檢查：{{ checkListTable.totalDataCount }} 人（已扣除未綁定手機）</p>

    <BaseElFormItem label="" prop="condition.type">
      <BaseElRadioGroup v-model="syncForm.type" clearable @change="onTypeChange">
        <div class="flex flex-col" style="gap: 20px">
          <div v-for="conditionType in rewardPostponeConditionTypesConfig" :key="conditionType.value">
            <BaseElRadio :label="conditionType.value">
              {{ conditionType.label }}
            </BaseElRadio>
            <BaseElButton v-if="syncForm.type === 'specific' && conditionType.value === 'specific'" plain @click="show.memberSelector = true">
              編輯名單
            </BaseElButton>
          </div>
        </div>
      </BaseElRadioGroup>
    </BaseElFormItem>

    <BaseElFormItem prop="condition.specificMemberIds">
      <section v-if="syncForm.type === rewardPostponeConditionTypesConfig.tag.value">
        <div v-for="(tagFilter, idx) in syncForm.tagSettings" :key="idx">
          <MemberTagCondition
            :tags="memberTags.data"
            :model.sync="syncForm.tagSettings[idx]"
            @update="refreshCheckList({ reCount: true })"
            @remove="onRemoveTagFilter(idx)"
          />

          <el-divider v-if="idx !== syncForm.tagSettings.length - 1" content-position="center">OR</el-divider>
        </div>
        <AddButton style="margin-top: 10px" @click="onAddTagFilter" />
      </section>
    </BaseElFormItem>

    <section>
      <p class="text-primary-100 font-bold" style="margin-bottom: 15px">預計檢查名單</p>
      <RewardPostponeCheckList
        :tableData="checkListTable"
        :rewardType="rewardType"
        @pageChange="onCheckListPageChange"
        @search="onCheckListSearch"
      />
    </section>

    <RewardPostponeMemberSelector
      v-if="show.memberSelector"
      :members="filterPhoneMembers"
      :rewardType="rewardType"
      @close="show.memberSelector = false"
      @confirm="onMembersSelect"
    />
  </div>
</template>

<script>
import { defineComponent, reactive, onMounted, ref, set, computed } from 'vue'
import { rewardPostponeTaskStatusConfig, rewardPostponeConditionTypesConfig } from '@/config/rewardPostpone'
import { useVModel } from '@vueuse/core'
import MemberTagCondition from '@/components/MemberTagCondition.vue'
import AddButton from '@/components/Button/AddButton.vue'
import RewardPostponeMemberSelector from './RewardPostponeMemberSelector.vue'
import RewardPostponeCheckList from './RewardPostponeCheckList.vue'
// api
import { GetTags, GetTagsCount } from '@/api/memberTags'
import { GetMembersNew, GetMembersCountNew } from '@/api/member'
import { GetMemberBatchTaskByConditions, CountMemberBatchTaskByConditions } from '@/api/memberBatchTask'
// use
import { useFetch } from '@/use/fetch'
import { useShop } from '@/use/shop'
import { pageStartIndex } from '@/utils/table'
import { pullAt, map, get, filter, omit, isEmpty } from 'lodash'
import { useFetchAll } from '@/use/useFetchAll'

export default defineComponent({
  name: 'RewardPostponeConditionBlock',
  components: { MemberTagCondition, AddButton, RewardPostponeMemberSelector, RewardPostponeCheckList },
  props: {
    rewardType: String,
    form: { type: Object, defalt: () => ({}) },
  },
  setup (props, { emit }) {
    const syncForm = useVModel(props, 'form', emit)
    const { fetchAll, fetchAllOld, simpleFetch } = useFetch()
    const { shopId } = useShop()
    const checkListTable = reactive({
      page: 1,
      perPage: 10,
      data: [],
      totalDataCount: 0,
    })
    // const memberTags = ref([])
    // const members = ref([])
    const checkList = ref([])
    const loading = ref(true)

    const filterPhoneMembers = computed(() => {
      return filter(members.data, m => get(m, 'UserInfo.phone'))
    })

    const show = reactive({
      memberSelector: false,
    })

    const computedConditions = computed(() => {
      if (syncForm.value.type === rewardPostponeConditionTypesConfig.specific.value) {
        return omit(syncForm.value, ['tagSettings'])
      } else if (syncForm.value.type === rewardPostponeConditionTypesConfig.tag.value) {
        return {
          type: syncForm.value.type,
          tagSettings: map(get(syncForm.value, 'tagSettings'), (item) => {
            const includes = get(item, 'includes')
            const excludes = get(item, 'excludes')

            return {
              includeTagIds: includes,
              excludeTagIds: excludes,
            }
          }),
        }
      }
      return omit(syncForm.value, ['tagSettings', 'specificMemberIds'])
    })

    const onAddTagFilter = () => {
      const defaultData = {
        includes: new Array(0),
        excludes: new Array(0),
      }
      syncForm.value.tagSettings.push(defaultData)
    }

    const resetCheckListTable = () => {
      checkListTable.data = 0
      checkListTable.totalDataCount = 0
      checkListTable.page = 1
    }

    const onRemoveTagFilter = (idx) => {
      const list = [...syncForm.value.tagSettings]
      pullAt(list, idx)
      set(syncForm.value, 'tagSettings', list)
      if (isEmpty(syncForm.value.tagSettings)) {
        resetCheckListTable()
      } else refreshCheckList({ reCount: true })
    }

    const onTypeChange = (type) => {
      set(syncForm.value, 'tagSettings', [])
      set(syncForm.value, 'specificMemberIds', [])
      resetCheckListTable()
      if (type === 'all') refreshCheckList({ reCount: true })
    }

    const onMembersSelect = (val) => {
      if (!val || isEmpty(val)) return
      checkList.value = val
      set(syncForm.value, 'specificMemberIds', map(val, 'id'))
      refreshCheckList({ reCount: true })
    }

    const checkConditionData = (condition) => {
      if (condition.type === rewardPostponeConditionTypesConfig.tag.value) {
        if (isEmpty(condition.tagSettings)) return false
      } else if (condition.type === rewardPostponeConditionTypesConfig.specific.value) {
        if (isEmpty(condition.specificMemberIds)) return false
      }
      return true
    }

    const refreshCheckList = async ({ reCount, search } = { reCount: false }) => {
      if (!checkConditionData(computedConditions.value)) return
      await simpleFetch(GetMemberBatchTaskByConditions, {
        shopId: shopId.value,
        condition: computedConditions.value,
        start: pageStartIndex(checkListTable.page, checkListTable.perPage),
        limit: checkListTable.perPage,
        memberId: get(search, 'member.id') || undefined,
      }, (res) => {
        checkListTable.data = filter(res, m => get(m, 'UserInfo.phone'))
      })

      if (reCount) {
        simpleFetch(CountMemberBatchTaskByConditions, {
          shopId: shopId.value,
          condition: computedConditions.value,
        }, (res) => {
          checkListTable.totalDataCount = res.count
        })
      }
    }

    const onCheckListPageChange = (newPage) => {
      checkListTable.page = newPage
      refreshCheckList()
    }

    const onCheckListSearch = async (search) => {
      refreshCheckList({ search })
    }

    const { fetchData: memberTags, execute: executeGetTagsAll } = useFetchAll(GetTagsCount, GetTags)
    const { fetchData: members, execute: executeGetMembersAll } = useFetchAll(GetMembersCountNew, GetMembersNew)

    onMounted(async () => {
      executeGetTagsAll({ shopId: shopId.value })
      executeGetMembersAll({ shopId: shopId.value })
    })

    return {
      rewardPostponeTaskStatusConfig,
      rewardPostponeConditionTypesConfig,
      syncForm,
      show,
      onAddTagFilter,
      onRemoveTagFilter,
      onTypeChange,
      memberTags,
      members,
      onMembersSelect,
      onCheckListPageChange,
      checkListTable,
      onCheckListSearch,
      refreshCheckList,
      filterPhoneMembers,
      loading,
    }
  },
})
</script>

  <style lang="postcss" scoped>
::v-deep .tag-conditions .el-form-item__label {
    @apply contents;
}
  </style>
